import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import * as Helper from '../util/Helper.js';
import TableStructure from './TableStructure';

const useStyles = makeStyles({
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  classBox: {
    padding: '6px 4px 4px',
    textAlign: 'center',
    color: '#fff',
  },
  classHolder: {
    paddingTop: 5,
  },
});

function InfoTable({ Data, proposalsOrdered, PublicClasses }) {
  const [tableData, setTableData] = useState();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        'https://api-eu-central-1.graphcms.com/v2/cke87cs6ervtn01xw86ua0ykd/master',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_GRAPH_CMS_TOKEN}`,
          },
          body: JSON.stringify({
            query:
              '{ proposals (first: 130) {id proposalNumber ap ppp uusiKem asuminen sailNykRak totalKem}, tableHeaders {id columnHeader}  }',
          }),
        }
      )
        .then(res => res.json())
        .then(res => res.data);

      setTableData(data);
    };

    fetchData();
  }, []);

  const classValueHtml = useCallback(
    value => (
      <div className={classes.class}>
        {value && (
          <div className={classes.classBox} style={{ backgroundColor: value.colour }}>
            {value.class}
          </div>
        )}
      </div>
    ),
    [classes.class, classes.classBox]
  );

  const spaceBetweenThousands = value =>
    value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';

  const generateColumns = () => {
    const accestors = {
      ckf2mi4vs01la0160ozse1na5: 'ap',
      ckf2mieww01la0108ozse1na5: 'ppp',
      ckf2mioy001ll01608rfglqw9: 'uusiKem',
      ckf2mj22801ly01087v2hqqwc: 'asuminen',
      ckf2mjf6g01ly0107t9m46b51: 'sailNykRak',
      ckf2mjofs01m50104c5kz1a0p: 'totalKem',
    };

    return (
      tableData &&
      tableData.tableHeaders.map(column => ({
        Header: column.columnHeader,
        accessor: accestors[column.id],
        Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
      }))
    );
  };

  const infoColumns = generateColumns();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Proposals', // Unique key and cannot remove
        columns: [
          {
            Header: 'No.',
            accessor: 'no',
          },
          {
            Header: 'Pseudonym',
            accessor: 'pseudonym',
          },
          {
            Header: 'Class',
            accessor: 'proposalClass',
            disableSorting: true,
            Cell: ({ cell: { value } }) => classValueHtml(value),
          },
        ],
      },
      {
        Header: 'Info', // Unique key and cannot remove
        columns: infoColumns,
      },
    ],
    [classValueHtml, infoColumns]
  );

  // const populateValueIntoCell = proposalNumber => {
  //   tableData &&
  //     tableData.proposals.map(proposal => {
  //       if (proposal.proposalNumber === proposalNumber) {
  //         return {
  //           ap: proposal.ap,
  //           ppp: proposal.ppp,
  //           uusiKem: proposal.uusiKem,
  //           asuminen: proposal.asuminen,
  //           sailNykRak: proposal.sailNykRak,
  //           totalKem: proposal.totalKem,
  //         };
  //       }
  //     });
  // };

  const generateArrayOfSingleRowObj = useCallback(() => {
    let singleRowObj = {};
    const initialStatisticsValueObj = {
      ap: '',
      ppp: '',
      uusiKem: '',
      asuminen: '',
      sailNykRak: '',
      totalKem: '',
    };
    let statisticsValueObj = { ...initialStatisticsValueObj };

    return _.map(Data, (value, key) => {
      const { number, name } = value;
      const proposalClass = Helper.getProposalClass(key, proposalsOrdered, PublicClasses);

      let proposalNoNameObj = {
        no: number,
        pseudonym: name,
      };

      // Add class into 'Class' column
      if (proposalClass) {
        proposalNoNameObj = {
          ...proposalNoNameObj,
          proposalClass: proposalClass,
        };
      }

      if (tableData && tableData.proposals.length) {
        const matchProposal = tableData.proposals.find(
          proposal => proposal.proposalNumber === number
        );

        statisticsValueObj = {
          ap: matchProposal ? matchProposal.ap : '',
          ppp: matchProposal ? matchProposal.ppp : '',
          uusiKem: matchProposal ? matchProposal.uusiKem : '',
          asuminen: matchProposal ? matchProposal.asuminen : '',
          sailNykRak: matchProposal ? matchProposal.sailNykRak : '',
          totalKem: matchProposal ? matchProposal.totalKem : '',
        };
      }

      // Combine proposal's no, name and statistic value object
      singleRowObj = {
        ...proposalNoNameObj,
        ...statisticsValueObj,
      };

      return singleRowObj;
    });
  }, [Data, PublicClasses, proposalsOrdered, tableData]);

  // Only rerender when data in table is changed
  const data = React.useMemo(() => generateArrayOfSingleRowObj(), [
    generateArrayOfSingleRowObj,
  ]);

  return <TableStructure columns={columns} data={data} disableMultiSort={true} />;
}

export default InfoTable;
