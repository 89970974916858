import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { filterProposals } from '../../redux/actions/proposalsActions';
import MultipleSelect from '../../inputs/MultipleSelect';
import { withRouter } from 'react-router-dom';
import * as searchHelper from '../../util/searchHelper';

const Classes = ({
  filterProposals,
  history,
  PublicClasses,
  proposals,
  selectedPhase,
}) => {
  const [name, setName] = useState([]);

  useEffect(() => {
    const { search } = history.location;
    if (!search) setName([]);
  }, [history.location]);

  useEffect(() => {
    const { search } = history.location;
    const searchQuery = searchHelper.getSearchQuery(search);

    if (searchQuery.classes) {
      const searchKeys = searchQuery.classes.split(',');
      setName(searchKeys);
    }
  }, [history.location]);

  const handleChange = event => {
    const value = event.target.value;

    if (value[value.length - 1] !== 'none') {
      setName(value);
    }
  };

  const applyFilters = () => {
    history.push({
      search: searchHelper.updateSearchQuery(history.location.search, 'classes', name),
    });
    filterProposals(history.location.search);
  };

  const selectAll = classes => {
    const values = classes.map(classe => classe.name);
    setName(values);
  };

  const clearAll = () => {
    setName([]);
  };

  const getPublicClasses = () => {
    const firebaseDataByPhase = _.filter(
      proposals,
      proposal => proposal.phaseId === selectedPhase
    );
    // This helps to get correct ordered of all classes after sort in Manage classes
    const copiedPublicClasses = _.map(PublicClasses, _.clone);
    const sortedPublicClasses = copiedPublicClasses
      .filter(element => element !== null)
      .sort((a, b) => a.position - b.position);

    const classes = PublicClasses
      ? sortedPublicClasses.map(val => {
          const amount = _.filter(firebaseDataByPhase, proposal => {
            const classID = _.get(proposal, 'class.id');
            return classID === val.id;
          });
          return {
            name: val.class,
            amount: amount.length,
            color: val.colour,
            id: val.id,
          };
        })
      : [];

    return classes;
  };

  return (
    <div id="filter-class">
      <MultipleSelect
        options={getPublicClasses()}
        values={name}
        onChange={handleChange}
        selectAll={() => selectAll(getPublicClasses())}
        clearAll={clearAll}
        placeholder="Classes"
        apply={applyFilters}
        id="filter-class"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  PublicClasses: state.firestore.data.PublicClasses,
  proposals: state.firestore.ordered.proposals,
  proposalsData: state.proposals,
  selectedPhase: state.proposals.selectedPhase,
});

export default compose(
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' },
  ]),
  connect(mapStateToProps, { filterProposals })
)(withRouter(Classes));
