import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Grid from '@material-ui/core/Grid';
import ManageUser from './ManageUser';
import Users from './Users';

const styles = theme => ({
  root: {
    padding: '32px',
    margin: 0,
  },
  tab: {
    fontSize: '1rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 270,
    minHeight: 32,
    marginBottom: 8,
    marginRight: 16,
    backgroundColor: theme.modeColors.tabBackground,
  },
  tabLabel: {
    padding: '4px 16px',
    alignItems: 'start',
  },

  selected: {
    backgroundColor: theme.modeColors.selectedTabBackground,
    fontWeight: 700,
  },
  tabContainer: {
    padding: 32,
    backgroundColor: theme.modeColors.navBackground,
    flex: 1,
  },
  lightThemeContainer: {
    backgroundColor: '#f7f8f9',
  },
  darkThemeContainer: {
    backgroundColor: '#000000',
    color: '#f7f8f9',
  },
  textModeColors: {
    color: theme.modeColors.proposalTitle,
  },
});

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs);

class Settings extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    const { profile, history } = this.props;
    if (profile && profile.role === 'judge') {
      history.push('/');
    }
  }

  openModal = () => {
    console.log('open modal');
    this.setState({ isModalOpen: true });
  };
  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleChange = (_, activeIndex) => this.props.setActiveSettingsTab(activeIndex);

  render() {
    const { classes, activeSettingsTab } = this.props;

    return (
      <div className={classes.root}>
        <h1 className={`mb-4 ${classes.textModeColors}`}>Settings </h1>
        <div className="d-flex">
          <VerticalTabs value={activeSettingsTab} onChange={this.handleChange}>
            <Tab
              label="Users"
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
            <Tab
              label="Theme"
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
          </VerticalTabs>

          {activeSettingsTab === 0 && (
            <div className={classes.tabContainer}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h3 className="mb-0 pb-1">Users </h3>
                <Button
                  onClick={this.openModal}
                  size="md"
                  variant="contained"
                  color="primary"
                >
                  + Add users
                </Button>
              </div>

              <Grid container className="border-bottom py-1">
                <Grid item xs={3} className="h5 mb-0">
                  Name
                </Grid>
                <Grid item xs={3} className="sm">
                  Email
                </Grid>
                <Grid item xs={3} className="sm">
                  Proffesion
                </Grid>
                <Grid item xs={3} className="sm">
                  Role
                </Grid>
              </Grid>
              <Users />
            </div>
          )}
          {activeSettingsTab === 1 && (
            <div className={classes.tabContainer}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h3 className="mb-0 pb-1">Toggle theme </h3>
              </div>

              <div>
                <div
                  className={`${classes.lightThemeContainer} px-2 py-1 mb-2 d-flex align-items-center justify-content-between`}
                  style={{ width: '100%', maxWidth: '400px' }}
                >
                  <p>Light theme</p>
                  <Button
                    onClick={() => {
                      this.props.clicked('light');
                    }}
                    size="md"
                    variant="contained"
                    color={this.props.themeMode === 'light' ? 'primary' : 'default'}
                  >
                    {this.props.themeMode === 'light' ? 'Activated' : 'Activate'}
                  </Button>
                </div>

                <div
                  className={`${classes.darkThemeContainer} px-2 py-1 d-flex align-items-center justify-content-between`}
                  onClick={() => {
                    this.props.clicked('dark');
                  }}
                  style={{ width: '100%', maxWidth: '400px' }}
                >
                  <p>Dark theme</p>
                  <Button
                    onClick={() => {
                      this.props.clicked('light');
                    }}
                    size="md"
                    variant="contained"
                    color={this.props.themeMode !== 'light' ? 'primary' : 'default'}
                  >
                    {this.props.themeMode !== 'light' ? 'Activated' : 'Activate'}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.isModalOpen && (
          <ManageUser close={this.closeModal} isOpen={this.state.isModalOpen} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Settings));
