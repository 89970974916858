import React from 'react';
import PropTypes from 'prop-types';

import KerrokantasiApi from './KerrokantasiApi';
import ExternalLink from './ManageKerrokantasiExternalLink';

const Comments = ({ proposalId, isAdmin, sectionIdForEachProposalID }) => {
  return (
    <>
      <ExternalLink isAdmin={isAdmin} proposalId={proposalId} />
      <KerrokantasiApi
        proposalId={proposalId}
        sectionIdForEachProposalID={sectionIdForEachProposalID}
      />
    </>
  );
};

Comments.propTypes = {
  proposalId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  sectionIdForEachProposalID: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Comments;
