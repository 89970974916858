import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import Rating from '@material-ui/lab/Rating';
import RadioIcon from '@material-ui/icons/Lens';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import Button from '../inputs/Button';
import * as Helpers from '../util/Helper';
import CustomModal from '../modal/CustomModal';

const styles = (theme) => ({
  rating_field: {
    fontSize: 14,
    paddingTop: '0.25rem',
    borderBottom: '1px solid #ecedee',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '2rem',
  },
  infoToolTip: {
    padding: '16px',
    fontSize: '14px',
  },
});

const infoIconContent = (
  <>
    <p>{'“Please, give rating for each topic which you are instructed to rate.”'}</p>
    <p>
      <i>
        {
          'Note: it is important to give rating to every proposal coherently. Topics that are left unrated will not affect the calculation of average value.'
        }
      </i>
    </p>
  </>
);

const StyledRating = withStyles({
  iconEmpty: {
    color: '#c7d1ff',
  },
  iconFilled: {
    color: '#394eb4',
  },
  iconHover: {
    color: '#394eb4',
  },
})(Rating);

const StyledRating2 = withStyles({
  iconEmpty: {
    color: '#bdbdbd',
  },
  iconFilled: {
    color: '#767676',
  },
  iconHover: {
    color: '#767676',
  },
})(Rating);

class ManageRatings extends React.Component {
  state = {
    rate: {},
    proposalId: null,
    updatedWithDb: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.proposalId) {
      return { proposalId: nextProps.match.params.id };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { proposalPrivateRate, rateSaved, unsetRateSaved } = this.props;
    // switch to new proposal, database rate is NOT empty
    const isNewProposal =
      prevState.proposalId !== this.state.proposalId &&
      !_.isEqual(proposalPrivateRate, this.state.rate);
    // rate state is not up to date with database rate (just land on page, or copying rate)
    const ratesNotUpdated = !_.isEqual(proposalPrivateRate, this.state.rate) && rateSaved;
    const ratesIsUpdated = _.isEqual(proposalPrivateRate, this.state.rate) && rateSaved;

    if (isNewProposal) {
      this.setState({ rate: proposalPrivateRate, updatedWithDb: true });
    } else if (ratesNotUpdated) {
      this.setState({
        rate: proposalPrivateRate,
        updatedWithDb: true,
      });
      unsetRateSaved();
    }

    if (ratesIsUpdated) unsetRateSaved();

    // Automatic save when switch proposal
    if (prevState.proposalId !== this.state.proposalId && this.props.isOpen) {
      this.props.onSave(prevState.rate, prevState.proposalId);
    }
  }

  componentDidMount() {
    const { proposalPrivateRate, match } = this.props;

    this.setState({ proposalId: match.params.id });
    this.setState({ rate: proposalPrivateRate, updatedWithDb: true });
  }

  getRatingFields = () => {
    const { fields, classes, proposalAverageRate, secretary } = this.props;
    const { rate } = this.state;
    // const ratingValues = !_.isEmpty(rate) ? rate[item.name] : 0;

    return fields.map((item, i) => {
      const handleChangeRating = (i, e, newValue) => {
        const newRate = _.merge({}, rate);
        newRate[item.name] = newValue;
        this.setState({ rate: newRate });
      };

      return (
        <div key={i}>
          <div className={classes.rating_field}>
            <div style={{ width: 285 }}>
              <span>{item.label}</span>
            </div>

            {proposalAverageRate && secretary && (
              <div>
                <StyledRating2
                  name={item.label}
                  value={proposalAverageRate[item.name] || 0}
                  precision={0.1}
                  icon={<RadioIcon fontSize="small" />}
                  readOnly
                />
              </div>
            )}

            <div>
              <StyledRating
                name={item.label}
                value={rate[item.name] || 0}
                precision={0.5}
                onChange={(e, newValue) => handleChangeRating(i, e, newValue)}
                onClick={(e) => this.handleClickRemoveRate(e)}
                icon={<RadioIcon fontSize="small" />}
              />
            </div>
          </div>
          {i === 0 && <div className={classes.rating_field}></div>}
        </div>
      );
    });
  };

  closeAndRest = () => {
    const { close } = this.props;

    this.saveRating();
    close();
  };

  saveRating = () => {
    const { rate, proposalId } = this.state;
    const { onSave } = this.props;

    onSave(rate, proposalId);
  };

  onSaveHandler = () => {
    const { close } = this.props;

    this.saveRating();
    close();
  };

  handleClickRemoveRate = (e) => {
    const { rate } = this.state;
    const targetEleName = e.target.getAttribute('name')
      ? e.target.getAttribute('name')
      : null;
    const targetEleValue = parseInt(e.target.getAttribute('value'));

    if (rate && targetEleName && targetEleValue) {
      _.forEach(rate, (value, key) => {
        if (targetEleName === Helpers.formatRateName(key) && targetEleValue === value) {
          this.setState({
            rate: {
              ...rate,
              [key]: null,
            },
          });
        }
      });
    }
  };

  copyRates = () => {
    this.props.copyAveragesRate();
  };

  clearAll = () => {
    this.setState({ rate: {} });
  };

  cancelAction = () => {
    // this.setState({ rate: this.props.proposalPrivateRate });
    this.props.close();
  };

  render() {
    const {
      title,
      isOpen,
      classes,
      proposal,
      proposalAverageRate,
      secretary,
    } = this.props;

    return (
      <CustomModal
        handleClose={this.closeAndRest}
        aria-labelledby="rating modal"
        show={isOpen}
        modalWidth={600}
      >
        <div className="p-3">
          <div className="mb-2">
            <div className="d-flex justify-content-between align-items-start">
              <h2>{title} </h2>
              <Tooltip
                title={infoIconContent}
                aria-label={infoIconContent}
                placement="left-start"
                TransitionComponent={Zoom}
                classes={{ tooltip: classes.infoToolTip }}
              >
                <InfoOutlineIcon />
              </Tooltip>
            </div>
            <h3>Proposal {proposal.number}</h3>
          </div>

          {proposalAverageRate && secretary && (
            <div className="d-flex justify-content-end sm">
              <div style={{ width: 126 }}>The average</div>
              <div style={{ width: 100 }}>Jury rating</div>
            </div>
          )}

          <div className=" border-top">{this.getRatingFields()}</div>

          <div
            className={`d-flex justify-content-${secretary ? 'start' : 'end'} mt-2`}
            style={{ marginLeft: secretary ? '310px' : '0' }}
          >
            {secretary && (
              <div className="mr-2">
                <Button onClick={this.copyRates} size="xs" color="primary">
                  Use averages
                </Button>
              </div>
            )}
            <Button onClick={this.clearAll} size="xs" color="primary">
              Clear all ratings
            </Button>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              size="md"
              variant="outlined"
              color="primary"
              onClick={this.cancelAction}
            >
              Cancel
            </Button>
            <Button
              size="md"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={this.onSaveHandler}
            >
              Save
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export default withStyles(styles)(ManageRatings);
