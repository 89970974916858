import axios from 'axios';

export const getKerrokantasiHearingData = () => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_KERROKANTASI_LOADING',
    });

    let response;
    const apiUrl = 'https://kerrokantasi-api.turku.fi/v1/hearing/linnanniemi/?';

    try {
      response = await axios.get(apiUrl);

      dispatch({
        type: 'FETCH_KERROKANTASI_SUCCESS',
        payload: response.data.sections,
      });
    } catch (error) {
      console.error('Could not get kerrokantasi hearing data', error);
      dispatch({
        type: 'FETCH_KERROKANTASI_ERROR',
        payload: error,
      });
    }
  };
};
