import * as React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { Tooltip, Box } from '@material-ui/core';
import firebase from '../util/fbconfig';

const useStyles = makeStyles({
  message: {
    borderRadius: '20px',
    width: '150px',
    maxWidth: 'fit-content',
    padding: '8px 20px',
    wordBreak: 'break-word',
    fontSize: '15px',
    color: '#000',
    userSelect: 'all',
  },
  bgBlueGreen: {
    backgroundColor: '#44bec7',
    color: '#fff',
  },
  bgGray: {
    backgroundColor: '#f2f3f5',
  },
  messagesContainer: {
    overflowY: 'scroll',
    paddingBottom: '3rem',
    height: '90%',
  },
  form: {
    position: 'fixed',
    bottom: 0,
    margin: '0 auto',
    padding: '10px 0',
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    border: 0,
    borderRadius: '20px',
    marginRight: '25px',
    width: '180px',
    backgroundColor: '#f2f3f5',
  },
  input: {
    border: 0,
    backgroundColor: '#f2f3f5',
    width: '100%',
    padding: '10px',
    borderRadius: '20px',
  },
  submitBtn: { border: 0, backgroundColor: 'transparent', cursor: 'pointer' },
});

function formatTime(timestamp) {
  const d = new Date(timestamp);
  const time = `${d.getDate()}/${d.getMonth() + 1} ${d.getHours()}:${d.getMinutes()}`;
  return time;
}

function MessageWithUserName({ chat }) {
  return (
    <Box mb="10px">
      <Box mb="1px" pl="20px" fontSize="9px">
        {chat.fullName}
      </Box>
      <Message chat={chat} isCurrentUser={false} />
    </Box>
  );
}

function Message({ chat, isCurrentUser }) {
  const classes = useStyles();
  const messageStyles = `${classes.message} ${
    isCurrentUser ? classes.bgBlueGreen : classes.bgGray
  }`;
  return (
    <Box
      mb="15px"
      display="flex"
      justifyContent={isCurrentUser ? 'flex-end' : 'flex-start'}
    >
      <Tooltip title={formatTime(chat.timestamp)} placement="left">
        <div className={messageStyles}>
          <div>{chat.content}</div>
        </div>
      </Tooltip>
    </Box>
  );
}

function Chat({ proposalId, profile }) {
  const [chats, updateChats] = React.useState([]);
  const [state, setState] = React.useState({
    user: firebase.auth().currentUser,
    content: '',
    readError: null,
    writeError: null,
  });
  const chatsRef = React.useRef(null);
  const classes = useStyles();

  React.useLayoutEffect(() => {
    if (!chatsRef.current) return;
    chatsRef.current.scrollTop = chatsRef.current.scrollHeight;
  });

  React.useEffect(() => {
    const getChats = () => {
      setState(state => ({ ...state, readError: null }));
      const chatsColRef = firebase
        .database()
        .ref('chats')
        .orderByChild('channelId')
        .equalTo(proposalId);

      try {
        chatsColRef.on('value', snapshot => {
          let chats = [];

          snapshot.forEach(snap => {
            chats.push(snap.val());
          });
          updateChats(chats);
        });
      } catch (error) {
        setState(state => ({ ...state, readError: error.message }));
      }
    };
    getChats();
  }, [proposalId]);

  const handleChange = event => {
    setState({ ...state, content: event.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!state.content) return;
    setState({ ...state, writeError: null });
    const chatsColRef = firebase.database().ref('chats');

    try {
      await chatsColRef.push({
        channelId: proposalId,
        content: state.content,
        timestamp: Date.now(),
        uid: state.user.uid,
        fullName: `${profile.firstName} ${profile.lastName}`,
      });
      setState({ ...state, content: '' });
    } catch (error) {
      setState({ ...state, writeError: error.message });
    }
  };

  return (
    <div ref={chatsRef} className={classes.messagesContainer}>
      <div className="chats">
        {chats.length === 0 ? (
          <p>Add first message</p>
        ) : (
          chats.map(chat => {
            const isCurrentUser = state.user.uid === chat.uid;
            return (
              <React.Fragment key={chat.timestamp}>
                {!isCurrentUser ? (
                  <MessageWithUserName chat={chat} />
                ) : (
                  <Message chat={chat} isCurrentUser={isCurrentUser} />
                )}
              </React.Fragment>
            );
          })
        )}
      </div>

      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.inputContainer}>
          <input
            onChange={handleChange}
            placeholder="Aa"
            value={state.content}
            className={classes.input}
          />
        </div>

        {state.error ? <p>{state.writeError}</p> : null}
        <button type="submit" className={classes.submitBtn}>
          <SendIcon style={{ color: '#44bec7' }} />
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = state => ({
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(Chat);
