import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const CommentContainer = styled.div`
  background-color: ${({ hasComment }) => (hasComment ? '#ececec' : '')};
  padding: ${({ hasComment }) => (hasComment ? '10px' : 0)};
`;

const CommentContent = styled.div`
  user-select: text;
  -moz-user-select: text;
  -webkit-user-drag: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  font-size: 14px;
  word-break: break-word;
  padding-left: 30px;
`;

const Comment = ({ comment, style = {} }) => (
  <CommentContainer key={comment.id} hasComment={!!comment} style={style}>
    <div className="d-flex align-items-center justify-content-start mb-1">
      <AccountCircleIcon />
      <h5 className="mb-0 ml-1">{comment.author_name}</h5>
    </div>

    <CommentContent>{comment.content}</CommentContent>
  </CommentContainer>
);

const KerrokantasiApi = ({ proposalId, sectionIdForEachProposalID }) => {
  const [state, setState] = useState({
    data: [],
    isLoading: false,
    isError: false,
  });
  const { isError, isLoading, data } = state;
  const sectionID = sectionIdForEachProposalID[proposalId];

  useEffect(() => {
    const fetchData = async () => {
      setState(state => ({ ...state, isLoading: true }));

      try {
        const result = await axios(
          `https://kerrokantasi-api.turku.fi/v1/comment/?section=${sectionID}`
        );
        const comments = result.data.results;
        const originalAscOrderByCreatedAt = comments.slice();

        setState(state => ({
          ...state,
          data: originalAscOrderByCreatedAt,
          isLoading: false,
          isError: false,
        }));
      } catch (error) {
        setState(state => ({ ...state, isLoading: false, isError: true }));
      }
    };

    fetchData();
  }, [sectionID]);

  const getReplyComments = rootCommentId => {
    const replyComments = data.filter(comment => comment.comment === rootCommentId);

    return replyComments.map(comment => (
      <Comment key={comment.id} comment={comment} style={{ paddingLeft: '30px' }} />
    ));
  };

  return (
    <>
      {isError && <div>No comments</div>}

      {isLoading ? (
        <Skeleton variant="text" width="50%" style={{ marginBottom: '20px' }} />
      ) : (
        !isError && (
          <div className="d-flex justify-content-start align-items-center mb-2">
            <h5 className="mb-0 mr-1">
              {data.length > 1
                ? 'Comments'
                : data.length === 0
                ? 'No comment'
                : 'A comment'}
              {data.length > 1 && `${' '}(${data.length})`}
            </h5>
          </div>
        )
      )}

      {isLoading &&
        Array.from(new Array(5)).map((element, index) => (
          <div key={`item-${index + 1}`} className="mb-2">
            <div className="d-flex align-items-center justify-content-start mb-1">
              <Skeleton
                variant="circle"
                width={40}
                height={40}
                style={{ marginRight: '10px' }}
              />
              <Skeleton variant="text" width="30%" />
            </div>
            <Skeleton
              variant="rect"
              width="100%"
              height="200px"
              style={{ marginTop: '10px' }}
            />
          </div>
        ))}

      {!isLoading &&
        data &&
        data.map((comment, index) => (
          <div key={comment.id} className="mb-2">
            {/* Root comment */}
            {comment && !comment.comment && <Comment comment={comment} />}

            {/* Reply comment */}
            {comment && getReplyComments(comment.id)}
          </div>
        ))}
    </>
  );
};

KerrokantasiApi.propTypes = {
  proposalId: PropTypes.string.isRequired,
  sectionIdForEachProposalID: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default KerrokantasiApi;
