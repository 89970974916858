import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';

import SettingsIcon from '@material-ui/icons/Settings';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import TextField from '../../inputs/Text';
import Button from '../../inputs/Button';
import * as Helper from '../../util/Helper.js';
import { addToDataBase } from '../../redux/actions/dataBase';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    position: 'relative',
    borderRadius: 0,
    width: 432,
    minHeight: 610,
    backgroundColor: theme.modeColors.inputBackground,
  },
}));

const StyledLink = styled('a')(({ isUrlEmpty }) => ({
  minWidth: '80px',
  marginRight: '20px',
  padding: '0.7rem 0.7rem 0.5rem',
  color: '#fff',
  backgroundColor: isUrlEmpty ? '#bfbfbf' : '#3f51b5',
  fontSize: '0.875rem',
  pointerEvents: isUrlEmpty ? 'none' : 'auto',
  cursor: isUrlEmpty ? 'default' : 'pointer',
  fontWeight: 700,
  textDecoration: 'none',
  wordBreak: 'break-word',
}));

const KerrokantasiExternalLink = ({
  isAdmin,
  proposalId,
  addToDataBase,
  enqueueSnackbar,
  proposalsData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    btnLabel: 'Kerrokantasi',
    url: '',
  });

  useEffect(() => {
    const getProposalKerrokantasiValues = (proposalId) => {
      const currProposal = _.find(proposalsData, proposalsData[proposalId]) || {};

      currProposal && currProposal.kerrokantasi
        ? setValues(currProposal.kerrokantasi)
        : setValues({
            btnLabel: 'Kerrokantasi',
            url: '',
          });
    };
    getProposalKerrokantasiValues(proposalId);
  }, [proposalId, proposalsData]);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const handleChangeValues = (event) => {
    const { value, name } = event.target;

    if (name === 'btnLabel') {
      setValues({ ...values, btnLabel: value });
    } else if (name === 'url') {
      setValues({ ...values, url: value });
    }
  };

  const saveValues = () => {
    const { btnLabel, url } = values;

    Helper.updateCommentsExternalLink(btnLabel, url, proposalId, {
      addToDataBase,
      enqueueSnackbar,
    });
    closeModal();
  };

  return (
    <div className="d-flex align-items-center justify-content-between pb-3">
      <StyledLink
        className="mb-0"
        href={values.url}
        target="_blank"
        isUrlEmpty={!values.url}
      >
        {values.btnLabel}
      </StyledLink>

      {isAdmin && (
        <Button style={{ padding: '5px' }} onClick={openModal} size="sm">
          <span
            className={`d-flex align-items-center`}
            style={{ color: theme.modeColors.textColor }}
          >
            <span className="mr-1">Manage </span>
            <SettingsIcon fontSize="small" />
          </span>
        </Button>
      )}

      {isModalOpen && (
        <Dialog
          onClose={closeModal}
          aria-labelledby="customized-dialog-title"
          open={isModalOpen}
          maxWidth="md"
          classes={{ paper: classes.dialogPaper }}
        >
          <div className="px-3 py-3">
            <div className="border-bottom pb-2 mb-3">
              <h2 className="mb-0">Manage Kerrokantasi URL</h2>
            </div>

            <div>
              <div className="mb-2">
                <TextField
                  value={values.btnLabel}
                  onChange={handleChangeValues}
                  name="btnLabel"
                  size="md"
                  id="outlined-basic"
                  label="Button label"
                  variant="outlined"
                />
              </div>

              <div className="mb-4">
                <TextField
                  value={values.url}
                  onChange={handleChangeValues}
                  name="url"
                  size="md"
                  id="outlined-basic"
                  label="URL"
                  variant="outlined"
                  placeholder="https://kerrokantasi.turku.fi/..."
                  multiline
                  rows={4}
                />
              </div>

              <div className="d-flex align-items-center justify-content-end mt-2">
                <Button
                  style={{ padding: '9px 12px 7px', marginRight: '10px' }}
                  size="md"
                  variant="outlined"
                  color="primary"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{ padding: '9px 12px 7px' }}
                  size="md"
                  variant="contained"
                  color="primary"
                  onClick={saveValues}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    proposalsData: state.firestore.data.proposals,
  };
};

export default compose(
  connect(mapStateToProps, { addToDataBase }),
  firestoreConnect([{ collection: 'proposals' }])
)(KerrokantasiExternalLink);
