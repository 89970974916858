import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  firebaseConnect,
  isLoaded,
  firestoreConnect,
  isEmpty,
} from 'react-redux-firebase';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './root/Root';
import { ThemeProvider } from 'styled-components';
import { getProposals, setPhaseId } from './redux/actions/proposalsActions';
import { getKerrokantasiHearingData } from './redux/actions/kerrokantasiActions';
import { SnackbarProvider } from 'notistack';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';

const lightTheme = createMuiTheme({
  modeColors: {
    textColor: '#000000',
    rootBackGround: '#f7f8f9',
    inputBorder: '#ccced0',
    guttersGridBg: '#ffffff',
    guttersGridAdvancedSearchColor: '#353c43',
    checkboxLabel: '#000000',
    checkbox: 'rgba(0, 0, 0, 0.54)',
    proposalTitle: '#353c43',
    tabBackground: 'transparent',
    selectedTabBackground: '#e0e2e4',
    singleProposalSideNavBg: '#ffffff',
    singleProposalSideNavText: 'inherit',
    buttonLabel: '#000000',
    elementImageContainer: '#6e7072',
    carouselBg: '#999da0',
    searchDetailsTitle: '#353c43',
    inputBackground: '#ffffff',
    navBackground: '#ffffff',
    cardBackground: '#ffffff',
    iconBtnBackground: '#ffffff',
    iconBtnBorder: '#bfbfbf',
    expandItemBackground: '#ffffff',
    headingColor: '#353c43',
    selectedColor: '#3f51b5',
  },
});

const darkTheme = createMuiTheme({
  modeColors: {
    textColor: '#f7f8f9',
    rootBackGround: '#000000',
    inputBorder: '#ffffff',
    guttersGridBg: '#2e2e2e',
    guttersGridAdvancedSearchColor: '#f7f8f9',
    checkboxLabel: '#f7f8f9',
    checkbox: '#bfbfbf',
    proposalTitle: '#f7f8f9',
    tabBackground: '#ccced0',
    selectedTabBackground: '#8790c3',
    singleProposalSideNavBg: '#2e2e2e',
    singleProposalSideNavText: '#f7f8f9',
    buttonLabel: '#f7f8f9',
    elementImageContainer: '#000000',
    carouselBg: '#2e2e2e',
    searchDetailsTitle: '#f7f8f9',
    inputBackground: '#bfbfbf',
    navBackground: '#bfbfbf',
    cardBackground: '#bfbfbf',
    iconBtnBackground: '#bfbfbf',
    iconBtnBorder: '#ffffff',
    expandItemBackground: '#2e2e2e',
    headingColor: '#f7f8f9',
    selectedColor: '#8790c3',
  },
});

const App = ({
  proposals,
  profile,
  getProposals,
  getKerrokantasiHearingData,
  proposalsData,
  PublicClasses,
}) => {
  const [themeMode, setThemeMode] = useState('light');
  const [isDataReady, setIsDataReady] = useState(false);

  const toggleState = mode => setThemeMode(mode);

  useEffect(() => {
    if (
      proposals.loading === undefined &&
      !isEmpty(profile) &&
      isLoaded(proposalsData) &&
      isLoaded(PublicClasses)
    ) {
      getProposals();
      getKerrokantasiHearingData();
    }
  });

  useEffect(() => {
    if (
      !isEmpty(profile) &&
      !proposals.loading &&
      isLoaded(proposalsData) &&
      isLoaded(PublicClasses)
    ) {
      setIsDataReady(true);
    }
  }, [proposals, proposalsData, profile, PublicClasses]);

  return (
    <Router>
      <SnackbarProvider maxSnack={5}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
            <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
              <Root
                themeMode={themeMode}
                toggleState={toggleState}
                isDataReady={isDataReady}
              />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </SnackbarProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    proposals: state.proposals,
    selectedPhase: state.proposals.selectedPhase,
    profile: state.firebase.profile,
    proposalsData: state.firestore.data.proposals ? state.firestore.data.proposals : {},
    PublicClasses: state.firestore.ordered.PublicClasses
      ? state.firestore.ordered.PublicClasses
      : [],
  };
};

const enhance = compose(
  firebaseConnect('profile'),
  firestoreConnect(props => {
    if (!props.firestore._.authUid) return [];
    return [
      { collection: 'proposals' },
      { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    ];
  }),
  connect(mapStateToProps, {
    getProposals,
    setPhaseId,
    getKerrokantasiHearingData,
  })
);

export default enhance(App);
