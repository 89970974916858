import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/styles';

import DownloadImage from './DownloadImage';
import CopyToClipboard from './CopyToClipboard';

const StyledText = styled('div')(({ theme }) => ({
  color: theme.modeColors.textColor,
  minHeight: '40px',
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: '1rem',
}));

const StyledH5 = styled('h5')(({ theme }) => ({
  color: theme.modeColors.headingColor,
}));

const RelativeContainer = styled('div')(() => ({
  position: 'relative',
}));

const AbsoluteElement = styled('div')(() => ({
  position: 'absolute',
  top: '-10px',
  right: '5px',
}));

class Elements extends Component {
  state = {
    elementKey: '',
  };

  componentDidMount = () => (document.body.style.overflow = 'hidden');

  mouseEnterShowIcons = event => {
    const value = event.currentTarget.getAttribute('value');

    this.setState({ elementKey: value });
  };

  mouseLeaveHideIcons = () => this.setState({ elementKey: '' });

  getTabIndex = (arr, elementId) => Object.keys(arr).findIndex(key => key === elementId);

  getElements = elements => {
    const { compareElements } = this.props;
    const activeBorder = id => {
      return compareElements && compareElements.includes(id);
    };
    if (elements) {
      const { reactAppUrl } = this.props;

      if (this.props.single) {
        const types = _.uniq(_.map(elements, item => item.type));

        return types.map((item, i) => (
          <Fragment key={i}>
            {item === 'panel' && (
              <Grid container justify="flex-start" spacing={1}>
                {this.getElementsByTypes(item, elements)}
              </Grid>
            )}
          </Fragment>
        ));
      } else {
        return (
          <Grid container justify="flex-start" spacing={1}>
            {_.map(elements, (value, key) => {
              const elementProposal = this.getElementProposl(key);
              const tabIndex = this.getTabIndex(elements, key);

              return (
                <Grid
                  key={key}
                  onClick={() => {
                    this.props.changeTab && this.props.changeTab(tabIndex);
                    this.props.openSingleElement(value, key, elementProposal);
                  }}
                  style={{
                    width: this.props.zoom * 100,
                    cursor: 'pointer',
                    border: activeBorder(key) ? '1px solid blue' : '',
                  }}
                  className="pb-1"
                  item
                >
                  <StyledH5 className="mb-0">
                    {elementProposal ? elementProposal[1].number : null}
                  </StyledH5>
                  <StyledText className="sm">{value.name}</StyledText>
                  <img
                    style={{
                      width: '100%',
                      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
                    }}
                    src={reactAppUrl + value.thumbnail_url}
                    alt={value.name}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      }
    }
    return null;
  };

  getElementsByTypes = (type, elements) => {
    const { zoom, reactAppUrl } = this.props;
    const { elementKey } = this.state;

    return Object.keys(elements).map((key, index) => {
      const value = elements[key];

      if (value.type === type) {
        return (
          <Grid
            key={key}
            onClick={() => this.props.openSingleElement(key, index)}
            style={{ width: zoom === 6 ? '100%' : zoom * 150, cursor: 'pointer' }}
            className="pb-1 mb-1"
            item
          >
            <StyledText>{value.name}</StyledText>

            <RelativeContainer
              onMouseEnter={key => this.mouseEnterShowIcons(key)}
              onMouseLeave={this.mouseLeaveHideIcons}
              value={key}
            >
              <img
                style={{ width: '100%', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)' }}
                src={reactAppUrl + value.thumbnail_url}
                alt={value.name}
              />

              <AbsoluteElement>
                <CopyToClipboard
                  imageSrc={reactAppUrl + value.pdf_thumbnail_url}
                  show={key === elementKey}
                />
                <DownloadImage
                  imageSrc={reactAppUrl + value.pdf_thumbnail_url}
                  imageName={value.name}
                  show={key === elementKey}
                />
              </AbsoluteElement>
            </RelativeContainer>
          </Grid>
        );
      }
      return null;
    });
  };

  getElementProposl = val => {
    const { proposals } = this.props;
    const proposal = _.pickBy(proposals, (value, key) => {
      const keys = Object.keys(value.elements);
      return keys.includes(val);
    });

    if (Object.keys(proposal).length > 1) {
      return Object.entries(_.pickBy(proposal, (value, key) => value.number !== 1))[0];
    } else {
      return Object.entries(proposal)[0];
    }
  };

  render() {
    const { elements } = this.props;
    const getElements = elements ? this.getElements(elements) : null;

    return <div>{getElements}</div>;
  }
}

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
    reactAppUrl: state.proposals.envValues.reactAppUrl,
  };
};

export default connect(mapStateToProps, null)(Elements);
