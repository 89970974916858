import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@material-ui/styles';

import { sortProposals } from '../redux/actions/proposalsActions';
import Select from '../inputs/Select';
import { createSortBy } from '../util/Helper';
import { addToDataBase } from '../redux/actions/dataBase';

const options = [
  {
    label: 'Random',
    value: 1,
  },
  {
    label: 'Number - First to last',
    value: 2,
  },
  {
    label: 'Number - Last to first',
    value: 3,
  },
  {
    label: 'Unseen first',
    value: 4,
  },
  {
    label: 'Seen first',
    value: 5,
  },
  {
    label: 'Category',
    value: 6,
  },
  {
    label: 'Jury Rating',
    value: 7,
  },
  {
    label: 'Secretary Rating',
    value: 8,
  },
];

const options2 = [
  {
    label: 'Random',
    value: 1,
  },
  {
    label: 'Number - First to last',
    value: 2,
  },
  {
    label: 'Number - Last to first',
    value: 3,
  },
];

const SortBy = styled('div')(({ theme }) => ({
  color: theme.modeColors.proposalTitle,
}));

const Sort = ({ sortProposals, profile, enqueueSnackbar, addToDataBase, userId }) => {
  const initialSortByValue = profile.sortBy ? profile.sortBy : 1;
  const [value, setValue] = useState(initialSortByValue);
  const readOnly = process.env.REACT_APP_READ_ONLY;

  const handleChange = event => {
    setValue(event.target.value);
    sortProposals(event.target.value);
    // Save to database user choice sort by value
    createSortBy(event.target.value, userId, addToDataBase, enqueueSnackbar);
  };

  return (
    <div id="sort-by" className="d-flex align-items-center">
      <SortBy className="sm pr-1">Sort by:</SortBy>
      <div>
        <Select
          onChange={handleChange}
          value={value}
          options={readOnly ? options2 : options}
          fixedWidth
        />
      </div>
    </div>
  );
};

Sort.propTypes = {
  sortProposals: PropTypes.func.isRequired,
  addToDataBase: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    userId: state.firebase.auth.uid,
  };
};

export default connect(mapStateToProps, { sortProposals, addToDataBase })(Sort);
