import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';

import TooltipMUI from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import CustomModal, { ModalMain } from '../modal/CustomModal';

const CustomModalMain = styled(ModalMain)`
  width: 850px;
  padding: 4rem 1rem;
  text-align: center;

  h3 {
    margin-bottom: 3rem;
  }
`;

const strokeColors = [
  '#8884d8',
  '#61be65',
  '#f3e200',
  '#ffa632',
  '#ff4c4c',
  '#D34E24',
  '#3f51b5',
  '#61a8be',
  '#bc61be',
  '#dedede',
  '#a74c32',
  '#b6a55c',
  '#ffa8ad',
  '#710627',
  '#38726C',
  '#BAA898',
  '#A57548',
  '#34435E',
  '#AA6373',
  '#03440C',
  '#FF8552',
  '#39393A',
  '#DF3B57',
  '#717744',
];

const renderLines = (arr, opacity) =>
  arr.map((reviewer, i) => (
    <Line
      key={reviewer}
      type="monotone"
      dataKey={reviewer}
      stroke={strokeColors[i]}
      strokeWidth="2"
      strokeOpacity={opacity[reviewer]}
    />
  ));

const renderCustomAxisTick = ({ x, y, payload }) => {
  let svg = '';

  switch (payload.value) {
    case 'Overall rating':
      svg = (
        <TooltipMUI TransitionComponent={Zoom} title="Overall rating" placement="bottom">
          <svg x={x - 10} y={y + 8} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M13.833 0H2.167C1.25 0 .5.75.5 1.667v11.666C.5 14.25 1.25 15 2.167 15h11.666c.917 0 1.667-.75 1.667-1.667V1.667C15.5.75 14.75 0 13.833 0zM5.5 11.667H3.833V5.833H5.5v5.834zm3.333 0H7.167V3.333h1.666v8.334zm3.334 0H10.5V8.333h1.667v3.334z"
              />
              <path d="M-2-2.5h20v20H-2z" />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Kilpailun tavoitteiden mukainen':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Kilpailun tavoitteiden mukainen"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
              />
              <path d="M0 0h24v24H0z" />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Kaupunkikuvallisesti ja arkkitehtonisesti korkeatasoinen':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Kaupunkikuvallisesti ja arkkitehtonisesti korkeatasoinen"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h24v24H0z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Linnan historiallisen arvon huomioiva':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Linnan historiallisen arvon huomioiva"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M9,4v1.38c-0.83-0.33-1.72-0.5-2.61-0.5c-1.79,0-3.58,0.68-4.95,2.05l3.33,3.33h1.11v1.11c0.86,0.86,1.98,1.31,3.11,1.36 V15H6v3c0,1.1,0.9,2,2,2h10c1.66,0,3-1.34,3-3V4H9z M7.89,10.41V8.26H5.61L4.57,7.22C5.14,7,5.76,6.88,6.39,6.88 c1.34,0,2.59,0.52,3.54,1.46l1.41,1.41l-0.2,0.2c-0.51,0.51-1.19,0.8-1.92,0.8C8.75,10.75,8.29,10.63,7.89,10.41z M19,17 c0,0.55-0.45,1-1,1s-1-0.45-1-1v-2h-6v-2.59c0.57-0.23,1.1-0.57,1.56-1.03l0.2-0.2L15.59,14H17v-1.41l-6-5.97V6h8V17z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Toiminnallisesti monipuolinen':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Toiminnallisesti monipuolinen"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={22} height={22} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-1.5h20v20H-1z" />
              <g fill="#353C43" fillRule="nonzero">
                <path d="M14.17,13.71l1.4-2.42c0.09-0.15,0.05-0.34-0.08-0.45l-1.48-1.16c0.03-0.22,0.05-0.45,0.05-0.68s-0.02-0.46-0.05-0.69 l1.48-1.16c0.13-0.11,0.17-0.3,0.08-0.45l-1.4-2.42c-0.09-0.15-0.27-0.21-0.43-0.15L12,4.83c-0.36-0.28-0.75-0.51-1.18-0.69 l-0.26-1.85C10.53,2.13,10.38,2,10.21,2h-2.8C7.24,2,7.09,2.13,7.06,2.3L6.8,4.15C6.38,4.33,5.98,4.56,5.62,4.84l-1.74-0.7 c-0.16-0.06-0.34,0-0.43,0.15l-1.4,2.42C1.96,6.86,2,7.05,2.13,7.16l1.48,1.16C3.58,8.54,3.56,8.77,3.56,9s0.02,0.46,0.05,0.69 l-1.48,1.16C2,10.96,1.96,11.15,2.05,11.3l1.4,2.42c0.09,0.15,0.27,0.21,0.43,0.15l1.74-0.7c0.36,0.28,0.75,0.51,1.18,0.69 l0.26,1.85C7.09,15.87,7.24,16,7.41,16h2.8c0.17,0,0.32-0.13,0.35-0.3l0.26-1.85c0.42-0.18,0.82-0.41,1.18-0.69l1.74,0.7 C13.9,13.92,14.08,13.86,14.17,13.71z M8.81,11c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C10.81,10.1,9.91,11,8.81,11z" />
                <path d="M21.92,18.67l-0.96-0.74c0.02-0.14,0.04-0.29,0.04-0.44c0-0.15-0.01-0.3-0.04-0.44l0.95-0.74 c0.08-0.07,0.11-0.19,0.05-0.29l-0.9-1.55c-0.05-0.1-0.17-0.13-0.28-0.1l-1.11,0.45c-0.23-0.18-0.48-0.33-0.76-0.44l-0.17-1.18 C18.73,13.08,18.63,13,18.53,13h-1.79c-0.11,0-0.21,0.08-0.22,0.19l-0.17,1.18c-0.27,0.12-0.53,0.26-0.76,0.44l-1.11-0.45 c-0.1-0.04-0.22,0-0.28,0.1l-0.9,1.55c-0.05,0.1-0.04,0.22,0.05,0.29l0.95,0.74c-0.02,0.14-0.03,0.29-0.03,0.44 c0,0.15,0.01,0.3,0.03,0.44l-0.95,0.74c-0.08,0.07-0.11,0.19-0.05,0.29l0.9,1.55c0.05,0.1,0.17,0.13,0.28,0.1l1.11-0.45 c0.23,0.18,0.48,0.33,0.76,0.44l0.17,1.18c0.02,0.11,0.11,0.19,0.22,0.19h1.79c0.11,0,0.21-0.08,0.22-0.19l0.17-1.18 c0.27-0.12,0.53-0.26,0.75-0.44l1.12,0.45c0.1,0.04,0.22,0,0.28-0.1l0.9-1.55C22.03,18.86,22,18.74,21.92,18.67z M17.63,18.83 c-0.74,0-1.35-0.6-1.35-1.35s0.6-1.35,1.35-1.35s1.35,0.6,1.35,1.35S18.37,18.83,17.63,18.83z" />
              </g>
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Innovatiivinen':
      svg = (
        <TooltipMUI TransitionComponent={Zoom} title="Innovatiivinen" placement="bottom">
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M12,3c-0.46,0-0.93,0.04-1.4,0.14C7.84,3.67,5.64,5.9,5.12,8.66c-0.48,2.61,0.48,5.01,2.22,6.56C7.77,15.6,8,16.13,8,16.69 V19c0,1.1,0.9,2,2,2h0.28c0.35,0.6,0.98,1,1.72,1s1.38-0.4,1.72-1H14c1.1,0,2-0.9,2-2v-2.31c0-0.55,0.22-1.09,0.64-1.46 C18.09,13.95,19,12.08,19,10C19,6.13,15.87,3,12,3z M14,19h-4v-1h4V19z M14,17h-4v-1h4V17z M12.5,11.41V14h-1v-2.59L9.67,9.59 l0.71-0.71L12,10.5l1.62-1.62l0.71,0.71L12.5,11.41z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Kokonaisnäkemykseltään vahva':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Kokonaisnäkemykseltään vahva"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-2.5h20v20H-1z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M20.57 14.86L22 13.43 20.57 12 17 15.57 8.43 7 12 3.43 10.57 2 9.14 3.43 7.71 2 5.57 4.14 4.14 2.71 2.71 4.14l1.43 1.43L2 7.71l1.43 1.43L2 10.57 3.43 12 7 8.43 15.57 17 12 20.57 13.43 22l1.43-1.43L16.29 22l2.14-2.14 1.43 1.43 1.43-1.43-1.43-1.43L22 16.29z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Kestävän kehityksen periaatteet huomioiva':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Kestävän kehityksen periaatteet huomioiva"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M21.94,4.88C21.76,4.35,21.25,4,20.68,4c-0.03,0-0.06,0-0.09,0H19.6l-0.31-0.97C19.15,2.43,18.61,2,18,2h0 c-0.61,0-1.15,0.43-1.29,1.04L16.4,4h-0.98c-0.03,0-0.06,0-0.09,0c-0.57,0-1.08,0.35-1.26,0.88c-0.19,0.56,0.04,1.17,0.56,1.48 l0.87,0.52L15.1,8.12c-0.23,0.58-0.04,1.25,0.45,1.62C15.78,9.91,16.06,10,16.33,10c0.31,0,0.61-0.11,0.86-0.32L18,8.98l0.81,0.7 C19.06,9.89,19.36,10,19.67,10c0.27,0,0.55-0.09,0.78-0.26c0.5-0.37,0.68-1.04,0.45-1.62l-0.39-1.24l0.87-0.52 C21.89,6.05,22.12,5.44,21.94,4.88z M18,7c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C19,6.55,18.55,7,18,7z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M13.49,10.51c-0.43-0.43-0.94-0.73-1.49-0.93V8h-1v1.38c-0.11-0.01-0.23-0.03-0.34-0.03c-1.02,0-2.05,0.39-2.83,1.17 c-0.16,0.16-0.3,0.34-0.43,0.53L6,10.52c-1.56-0.55-3.28,0.27-3.83,1.82c0,0,0,0,0,0c-0.27,0.75-0.23,1.57,0.12,2.29 c0.23,0.48,0.58,0.87,1,1.16c-0.38,1.35-0.06,2.85,1,3.91c1.06,1.06,2.57,1.38,3.91,1c0.29,0.42,0.68,0.77,1.16,1 C9.78,21.9,10.21,22,10.65,22c0.34,0,0.68-0.06,1.01-0.17c0,0,0,0,0,0c1.56-0.55,2.38-2.27,1.82-3.85l-0.52-1.37 c0.18-0.13,0.36-0.27,0.53-0.43c0.87-0.87,1.24-2.04,1.14-3.17H16v-1h-1.59C14.22,11.46,13.92,10.95,13.49,10.51z M4.67,14.29 c-0.25-0.09-0.45-0.27-0.57-0.51s-0.13-0.51-0.04-0.76c0.19-0.52,0.76-0.79,1.26-0.61l3.16,1.19C7.33,14.2,5.85,14.71,4.67,14.29z M10.99,19.94c-0.25,0.09-0.52,0.08-0.76-0.04c-0.24-0.11-0.42-0.32-0.51-0.57c-0.42-1.18,0.09-2.65,0.7-3.8l1.18,3.13 C11.78,19.18,11.51,19.76,10.99,19.94z M12.2,14.6l-0.61-1.61c0-0.01-0.01-0.02-0.02-0.03c-0.02-0.04-0.04-0.08-0.06-0.12 c-0.02-0.04-0.04-0.07-0.07-0.11c-0.03-0.03-0.06-0.06-0.09-0.09c-0.03-0.03-0.06-0.06-0.09-0.09c-0.03-0.03-0.07-0.05-0.11-0.07 c-0.04-0.02-0.07-0.05-0.12-0.06c-0.01,0-0.02-0.01-0.03-0.02L9.4,11.8c0.36-0.29,0.79-0.46,1.26-0.46c0.53,0,1.04,0.21,1.41,0.59 C12.8,12.66,12.84,13.81,12.2,14.6z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Vaiheittain toteutettavissa':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Vaiheittain toteutettavissa"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M9,15c-2.67,0-8,1.34-8,4v2h16v-2C17,16.34,11.67,15,9,15z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M22.1,6.84c0.01-0.11,0.02-0.22,0.02-0.34c0-0.12-0.01-0.23-0.03-0.34l0.74-0.58c0.07-0.05,0.08-0.15,0.04-0.22l-0.7-1.21 c-0.04-0.08-0.14-0.1-0.21-0.08L21.1,4.42c-0.18-0.14-0.38-0.25-0.59-0.34l-0.13-0.93C20.36,3.06,20.29,3,20.2,3h-1.4 c-0.09,0-0.16,0.06-0.17,0.15L18.5,4.08c-0.21,0.09-0.41,0.21-0.59,0.34l-0.87-0.35c-0.08-0.03-0.17,0-0.21,0.08l-0.7,1.21 c-0.04,0.08-0.03,0.17,0.04,0.22l0.74,0.58c-0.02,0.11-0.03,0.23-0.03,0.34c0,0.11,0.01,0.23,0.03,0.34l-0.74,0.58 c-0.07,0.05-0.08,0.15-0.04,0.22l0.7,1.21c0.04,0.08,0.14,0.1,0.21,0.08l0.87-0.35c0.18,0.14,0.38,0.25,0.59,0.34l0.13,0.93 C18.64,9.94,18.71,10,18.8,10h1.4c0.09,0,0.16-0.06,0.17-0.15l0.13-0.93c0.21-0.09,0.41-0.21,0.59-0.34l0.87,0.35 c0.08,0.03,0.17,0,0.21-0.08l0.7-1.21c0.04-0.08,0.03-0.17-0.04-0.22L22.1,6.84z M19.5,7.75c-0.69,0-1.25-0.56-1.25-1.25 s0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25S20.19,7.75,19.5,7.75z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M19.92,11.68l-0.5-0.87c-0.03-0.06-0.1-0.08-0.15-0.06l-0.62,0.25c-0.13-0.1-0.27-0.18-0.42-0.24l-0.09-0.66 C18.12,10.04,18.06,10,18,10h-1c-0.06,0-0.11,0.04-0.12,0.11l-0.09,0.66c-0.15,0.06-0.29,0.15-0.42,0.24l-0.62-0.25 c-0.06-0.02-0.12,0-0.15,0.06l-0.5,0.87c-0.03,0.06-0.02,0.12,0.03,0.16l0.53,0.41c-0.01,0.08-0.02,0.16-0.02,0.24 c0,0.08,0.01,0.17,0.02,0.24l-0.53,0.41c-0.05,0.04-0.06,0.11-0.03,0.16l0.5,0.87c0.03,0.06,0.1,0.08,0.15,0.06l0.62-0.25 c0.13,0.1,0.27,0.18,0.42,0.24l0.09,0.66C16.89,14.96,16.94,15,17,15h1c0.06,0,0.12-0.04,0.12-0.11l0.09-0.66 c0.15-0.06,0.29-0.15,0.42-0.24l0.62,0.25c0.06,0.02,0.12,0,0.15-0.06l0.5-0.87c0.03-0.06,0.02-0.12-0.03-0.16l-0.52-0.41 c0.01-0.08,0.02-0.16,0.02-0.24c0-0.08-0.01-0.17-0.02-0.24l0.53-0.41C19.93,11.81,19.94,11.74,19.92,11.68z M17.5,13.33 c-0.46,0-0.83-0.38-0.83-0.83c0-0.46,0.38-0.83,0.83-0.83s0.83,0.38,0.83,0.83C18.33,12.96,17.96,13.33,17.5,13.33z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M4.74,9h8.53c0.27,0,0.49-0.22,0.49-0.49V8.49c0-0.27-0.22-0.49-0.49-0.49H13c0-1.48-0.81-2.75-2-3.45V5.5 C11,5.78,10.78,6,10.5,6S10,5.78,10,5.5V4.14C9.68,4.06,9.35,4,9,4S8.32,4.06,8,4.14V5.5C8,5.78,7.78,6,7.5,6S7,5.78,7,5.5V4.55 C5.81,5.25,5,6.52,5,8H4.74C4.47,8,4.25,8.22,4.25,8.49v0.03C4.25,8.78,4.47,9,4.74,9z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M9,13c1.86,0,3.41-1.28,3.86-3H5.14C5.59,11.72,7.14,13,9,13z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Teknistaloudellisesti toteuttamiskelpoinen':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Teknistaloudellisesti toteuttamiskelpoinen"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M9,15c-2.67,0-8,1.34-8,4v2h16v-2C17,16.34,11.67,15,9,15z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M13,8.57c-0.79,0-1.43,0.64-1.43,1.43s0.64,1.43,1.43,1.43s1.43-0.64,1.43-1.43S13.79,8.57,13,8.57z"
              />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M13,3C9.25,3,6.2,5.94,6.02,9.64L4.1,12.2C3.85,12.53,4.09,13,4.5,13H6v3c0,1.1,0.9,2,2,2h1v3h7v-4.68 c2.36-1.12,4-3.53,4-6.32C20,6.13,16.87,3,13,3z M16,10c0,0.13-0.01,0.26-0.02,0.39l0.83,0.66c0.08,0.06,0.1,0.16,0.05,0.25 l-0.8,1.39c-0.05,0.09-0.16,0.12-0.24,0.09l-0.99-0.4c-0.21,0.16-0.43,0.29-0.67,0.39L14,13.83c-0.01,0.1-0.1,0.17-0.2,0.17h-1.6 c-0.1,0-0.18-0.07-0.2-0.17l-0.15-1.06c-0.25-0.1-0.47-0.23-0.68-0.39l-0.99,0.4c-0.09,0.03-0.2,0-0.25-0.09l-0.8-1.39 c-0.05-0.08-0.03-0.19,0.05-0.25l0.84-0.66C10.01,10.26,10,10.13,10,10c0-0.13,0.02-0.27,0.04-0.39L9.19,8.95 c-0.08-0.06-0.1-0.16-0.05-0.26l0.8-1.38c0.05-0.09,0.15-0.12,0.24-0.09l1,0.4c0.2-0.15,0.43-0.29,0.67-0.39l0.15-1.06 C12.02,6.07,12.1,6,12.2,6h1.6c0.1,0,0.18,0.07,0.2,0.17l0.15,1.06c0.24,0.1,0.46,0.23,0.67,0.39l1-0.4c0.09-0.03,0.2,0,0.24,0.09 l0.8,1.38c0.05,0.09,0.03,0.2-0.05,0.26l-0.85,0.66C15.99,9.73,16,9.86,16,10z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Onnistunut historian ja tulevaisuuden museon ratkaisun suhteen':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Onnistunut historian ja tulevaisuuden museon ratkaisun suhteen"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={24} height={24} viewBox="0 0 64 64">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                d="M33,13a2,2,0,0,0-3.33-1.49.975.975,0,0,1-1.08.17.987.987,0,0,1-.59-.91V4H10V22h5.13A3.708,3.708,0,0,1,15,21a4,4,0,0,1,8,0,3.708,3.708,0,0,1-.13,1H28V15.23a1,1,0,0,1,1.67-.74A2,2,0,0,0,33,13Z"
              />
              <path
                fill="#353C43"
                d="M33,33a2.006,2.006,0,0,0-2-2,1.953,1.953,0,0,0-1.33.52A1,1,0,0,1,28,30.78V24H21.22a1,1,0,0,1-.74-1.67A1.953,1.953,0,0,0,21,21a2,2,0,0,0-4,0,1.953,1.953,0,0,0,.52,1.33A1,1,0,0,1,16.78,24H10V42h5.13A3.708,3.708,0,0,1,15,41a4,4,0,0,1,8,0,3.708,3.708,0,0,1-.13,1H28V35.22a1,1,0,0,1,1.67-.74A1.953,1.953,0,0,0,31,35,2.006,2.006,0,0,0,33,33Z"
              />
              <path
                fill="#353C43"
                d="M45,38a2.974,2.974,0,0,1,2,.78,3.174,3.174,0,0,1,1-.6V24H44.87A3.708,3.708,0,0,1,45,25a4,4,0,0,1-8,0,3.708,3.708,0,0,1,.13-1H30v5.13A3.708,3.708,0,0,1,31,29a4,4,0,0,1,0,8,3.708,3.708,0,0,1-1-.13V42h7.59l.41-.41V34a3,3,0,0,1,6,0v4.18A3,3,0,0,1,45,38Z"
              />
              <path
                fill="#353C43"
                d="M48,4H30V9.13A3.708,3.708,0,0,1,31,9a4,4,0,0,1,0,8,3.708,3.708,0,0,1-1-.13V22h8.78a1,1,0,0,1,.74,1.67A1.953,1.953,0,0,0,39,25a2,2,0,0,0,4,0,1.953,1.953,0,0,0-.52-1.33A1,1,0,0,1,43.22,22H48Z"
              />
              <path
                fill="#353C43"
                d="M51.2,57.4,54,53.67V42a1,1,0,0,0-2,0v6H50V41a1,1,0,0,0-2,0v7H46V41a1,1,0,0,0-2,0v7H42V34a1,1,0,0,0-2,0V48H38V44.41l-1,1v7.26l2.8,3.73a.984.984,0,0,1,.2.6v5H51V58A.984.984,0,0,1,51.2,57.4Z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    default:
      svg = '';
  }

  return svg;
};

const createLegendOpacityObj = (lineKeyDataArr) => {
  const outputObj = {};

  lineKeyDataArr.map(
    (reviewer) =>
      (outputObj['opacity'] = {
        ...outputObj.opacity,
        [reviewer]: 1,
      })
  );

  return outputObj;
};

const MultipleLinesChart = ({ isOpen, close, allRates, proposal }) => {
  const lineKeyDataArr = Object.keys(allRates.rates[0]).filter((e) => e !== 'name');
  const legendOpacityObj = createLegendOpacityObj(lineKeyDataArr);
  const [linesOpacity, setLinesOpacity] = useState(legendOpacityObj);

  const handleMouseEnter = (o) => {
    // o is default object from recharts and it's available on mouse event
    const { dataKey } = o;

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 0.5,
      },
    });
  };

  const handleMouseLeave = (o) => {
    // o is default object from recharts and it's available on mouse event
    const { dataKey } = o;

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 1,
      },
    });
  };

  return (
    <CustomModal show={isOpen} handleClose={close}>
      <CustomModalMain>
        <h3>Proposal {proposal.number}</h3>

        <LineChart
          width={800}
          height={400}
          data={allRates.rates}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} angle={30} tick={renderCustomAxisTick} />
          <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
          <Tooltip />
          <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            wrapperStyle={{ right: '0', transform: 'translateX(30%)' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />

          {/* Lines */}
          {renderLines(lineKeyDataArr, linesOpacity.opacity)}
        </LineChart>
      </CustomModalMain>
    </CustomModal>
  );
};

MultipleLinesChart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  allRates: PropTypes.object.isRequired,
  proposal: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
    .isRequired,
};

export default MultipleLinesChart;
